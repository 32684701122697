.about-personal {
 -webkit-box-orient: vertical;
 -webkit-box-direction: normal;
 display: flex;
 position: relative;
 flex-direction: column;
 width: min(350px, 95%);
 margin: 20px auto;
 justify-content: center;
 align-items: center;
}

.personal-photo {
 -webkit-box-flex: 0;
 flex: 0 0 100%;
 width: 100%;
 margin: 0 0 90px 0;
}

.personal-info {
 position: absolute;
 -webkit-box-flex: 0;
 flex: 0 0 100%;
 max-width: 100%;
 bottom: 0;
 left: 0;
 right: 0;
 padding: 20px 30px;
 overflow: hidden;
 line-height: 1.25;
}

.personal-info > p {
 font-size: 18px;
 color: rgb(200, 200, 200);
}