.container {
 position: relative;
 margin-left: auto;
 margin-right: auto;
 padding-left: 6px;
 padding-right: 6px;
}

.header {
 background-color: rgb(46, 46, 46);
 display: block;
 unicode-bidi: isolate;
 position: fixed;
 height: auto;
 width: 100%;
 z-index: 999;
}

.header-row {
 display: flex;
 flex-direction: row;
 margin-right: -6px;
 margin-left: -6px;
}

.header-icon {
 background-image: url('./images/icon.png');
 background-position: center;
 background-size: contain;
 background-repeat: no-repeat; 
 width: 100%;
 max-width: 160px;
}

.header-nav {
 flex-basis: 0;
 flex-grow: 1;
 max-width: 100%;
}

.header-list {
 display: flex;
 -webkit-box-pack: center;
 justify-content: center;
}

.header-list > li {
 display: inline-block;
}

.header-element {
 font-size: 16px;
 padding: 9px;
 display: flex;
 -webkit-box-align: center;
 align-items: center;
 white-space: nowrap;
 color: rgb(212, 212, 212);
}

.footer {
 font-size: 14px;
 text-align: center;
 color: white;
 background-color: rgb(46, 46, 46);
 height: 60px;
}

.footer-container {
 display: flex;
 width: 100%;
 height: 100%;
 align-items: center;
 justify-content: center;
}

.footer-container p::before {
 content: '\00A9';
}