.faq-styled {
 background-image: url('../images/back/short/faq-back-blur.jpg');
 background-size: cover;
 background-position: 40%;
 width: 100%;
 height: 100vh;
 transition: height 0.3s ease-out;
}

.faq-block {
 padding: 8px 0;
 display: flex;
 justify-content: center;
 align-items: center;
}

.faq-container {
 margin-top: 25%;

 background-color: rgb(255 255 255 / 30%);
 backdrop-filter: blur(20px);
 border-radius: 8px;
 display: flex;
 flex-direction: column;
 align-items: center;
 justify-content: center;
 width: calc(100% - 20px);
 padding: 20px 10px;
}