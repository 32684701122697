.back-styled {
 background-image: url('../images/back//short/contacts-back-blur.jpg');
 padding: 0 0 55px;
 background-size: cover;
 background-position: 50%;
 width: 100%;
 height: 100vh;
 transition: height 0.3s ease-out;
}

.contacts-block {
 display: flex;
 flex-direction: column;
 -webkit-box-align: center;
 align-items: center;
 justify-content: flex-end;
 width: 100%;
 position: absolute;
 bottom: -90vh;
}

.contact-name {
 font-size: 2rem;
 font-weight: 600;
}

.social-list {
 display: flex;
 padding: 0;
 -webkit-box-align: center;
 align-items: center;
 justify-content: center;
}

.social-list li {
 display: block;
 padding: 0 10px;
 width: 18vw;
 height: 18vw;
}

.contact-phone {
 text-decoration: none;
 color: white;
 font-size: 32px;
}

.contact-mail {
 font-size: 26px;
 margin-bottom: 20px;
}