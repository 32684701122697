.section-default {
 position: relative;
 min-height: 100vh;
 background-color: black;
}

.section-content {
 padding: 45px 0 55px;
 min-height: 100vh;
 display: flex;
 -webkit-box-align: center;
 align-items: flex-start;
}

.section-row {
 display: flex;
 justify-content: center;
 align-items: center;
 flex-wrap: wrap;
 margin-left: -6px;
 margin-right: -6px;
}

.section-title {
 display: flex;
 flex-wrap: nowrap;
 justify-content: center;
 align-items: center;
 width: 80%;
}

.section-title::before, .section-title::after {
 content: '';
 flex: 1 1 auto;
 border-bottom: solid 1px rgb(200, 200, 200);
}

.title-info {
 font-size: 24px;
 letter-spacing: 5px;
 font-weight: 600;
 color: rgb(200, 200, 200);
 padding: 0 15px;
}

.section-col {
 position: relative;
 width: 100%;
 min-height: 1px;
 padding-right: 6px;
 padding-left: 6px;
 -webkit-box-flex: 0;
 flex: 0 0 100%;
 max-width: 100%;
}

.section-block {
 color: white;
 position: relative;
}