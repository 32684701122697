.first-screen {
 background-image: url('./images/back/short/header-main-blur.jpg');
 background-size: cover;
 background-position: 50%;
 width: 100%;
 height: 100vh;
 transition: height 0.3s ease-out;
}

.first-screen__wrapper {
 background-position: 50%;
 display: flex;
 -webkit-box-orient: vertical;
 -webkit-box-direction: normal;
 flex-direction: column;
 -webkit-box-align: center;
 align-items: center;
 -webkit-box-pack: end;
 justify-content: flex-end;
 width: 100%;
 height: 100%;
 background-size: cover;
}

.first-screen__content {
 position: absolute;
 top: 0;
 left: 0;
 right: 0;
 bottom: 0;
}

.first-screen-container {
 display: flex;
 -webkit-box-align: end;
 align-items: center;
 justify-content: flex-end;
 flex-direction: column;
 height: 100vh;
 transition: height 0.5s ease-out;
}

.first-main-block {
 display: flex;
 width: 100%;
 color: white;
 flex-wrap: wrap;
 -webkit-box-pack: center;
 justify-content: center;

 margin-bottom: 5vh;
}

.first-main-logo {
 height: auto;
 flex: 0 0 25%;
 max-width: 100%;
 display: flex;
 -webkit-box-align: center;
 align-items: center;
 -webkit-box-pack: center;
 justify-content: center;
}

.first-main-logo > img {
 height: auto;
 width: auto;
 display: block;
 border-style: none;
}

.first-main-button {
 margin: 3vh auto 0;
}

.main-button {
 display: flex;
 max-width: 300px;
 padding: 12px 50px;
 background-color: #E0E0E0;
 color: #3E2723;
 font-size: 18px;
 line-height: 28px;
 border-radius: 20px;
 justify-content: center;
 -webkit-box-pack: center;
 margin: 0 auto 20px;
 transition: all ease 0.25s;
 overflow: hidden;
 -webkit-user-select: none; /* Safari */
 user-select: none; /* Standard */

 box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
}