.accordition {
 display: flex;
 width: 100%;
 flex-direction: column;
}

.accordition-check {
 display: none;
}

.accordition-title {
 font-size: 21px;
 margin: 10px 0;
 border-bottom: 1px dashed white;
}

.accordition-label {
 display: grid;
 grid-template-rows: auto 0fr;
 padding: 0 18px;
 width: 100%;
 transition: grid-template-rows 0.2s ease;
 overflow: hidden; 
}

.accordition-label .accordition-container {
 overflow: hidden;
}

.accordition-check:checked+.accordition-label {
 grid-template-rows: auto 1fr;
}

.accordition-check:checked + .accordition-label > .accordition-title {
 border-bottom: 0;
}