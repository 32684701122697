.video-block {
 margin: 0 auto;
 margin-bottom: 40px;
}

.youtube-content {
 display: flex;
 justify-content: center;
 align-items: center;
 margin: 20px 0;
}

.youtube-content iframe {
 width: 80%;
 height: 45vw;
}