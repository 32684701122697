@import "~react-image-gallery/styles/css/image-gallery.css";

.stack-panel {
 position: relative;
 display: grid;
 grid-template-columns: 50% 50%;
 padding: 20px 10px 0 10px;
 gap: 8px;
 align-items: flex-start;
 justify-content: center;
}

.stack-actions {
 width: 100%;
 display: flex;
 padding: 20px 20px 0;
}

.stack-column {
 display: flex;
 flex-direction: column;
 gap: 8px;
 align-items: center;
 justify-content: center;
}

.stack-item {
 border-radius: 4px;
 overflow: hidden;
}

.stack-item img {
 width: 100%;
 transform: scale(1.25, 1.25);
}

.gallery {
 position: fixed;
 opacity: 1;
 width: 100%;
 height: 100%;
 z-index: 1000;
 backface-visibility: hidden;
 overflow: hidden;
 top: 0;
 left: 0;
 right: 0;
 bottom: 0;
}

.gallery-container {
 display: flex;
 justify-content: center;
 align-items: center;
 width: 100%;
 height: 100%;
}

.gallery-block {
 background-color: black;
 opacity: 0.9;
 position: absolute;
 height: 100%;
 width: 100%;
}

.gallery-content {
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;
 height: 100%;
}

.gallery-actions {
 position: absolute;
 width: 100%;
 bottom: 0;
 left: 0;
}

.gallery-button {
 display: flex;
 max-width: 200px;
 padding: 12px 50px;
 background-color: rgb(30, 30, 30);
 color: rgb(200, 200, 200);
 font-size: 18px;
 line-height: 20px;
 border-radius: 20px;
 justify-content: center;
 -webkit-box-pack: center;
 margin: 0 auto 20px;
 transition: all ease 0.25s;
 overflow: hidden;
 -webkit-user-select: none;
 /* Safari */
 user-select: none;
 /* Standard */
 border: 1px solid rgb(200, 200, 200);
 user-select: none;
 cursor: pointer;
}