.team-form {
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;
}

.team-photo-duo {
 width: min(350px, 90%);
}

.team-info-duo {
 position: absolute;
 flex: 0 0 100%;
 top: 0;
 bottom: 0;
 left: 0;
 right: 0;
 padding: 20px;
 margin: 375px 0 0 0;
}

.team-info-duo>h2 {
 letter-spacing: 5px;
}

.team-info-duo>p {
 font-size: 18px;
 color: rgb(200, 200, 200);
 text-align: center;
}

.team-photo-band {
 width: 100%;
}

.team-info-band {
 padding: 0 20px;
}

.team-info-band>p {
 font-size: 18px;
 color: rgb(200, 200, 200);
 text-align: center;
}